<script setup lang="ts">
import { Header } from '@upa/design-system';
const config = useRuntimeConfig();

const { count } = useCart();
//const { count: wishlistCount } = useWishlist();
const localePath = useLocalePath();
const { formatLink } = useInternationalization(localePath);

const sidebarController = useModal();
const router = useRouter();

withDefaults(
  defineProps<{
    headerHeadline: string;
    withoutIcon?: boolean;
    hideCartIcon?: boolean;
  }>(),
  { withoutIcon: false },
);

const goToAccount = async () => {
  await router.push(formatLink('/account/order'));
};

const isAccount = ref(
  router.currentRoute?.value?.meta?.layout === 'account' ? true : false,
);

const goToHome = () => {
  const homeLink = formatLink('/');
  router.push(homeLink);
};

// const { logout } = useUser();
// // const { successAfterLogout } = useAuth();
// async function invokeLogout() {
//   await logout();
//   router.push(formatLink('/login'));
//   // Remove context token from cookies
//   const contextToken = useCookie('sw-context-token');
//   contextToken.value = null;

//   /**
//    * TODO: FIX auth method `successAfterLogout()` -> does not log out in certain contexts
//    */
//   // successAfterLogout();
// }

//router.push(formatLink('/account'))
</script>

<template>
  <Header
    :src="$t('header.logo.src') != '' ? $t('header.logo.src') : ''"
    :content="headerHeadline"
    :professional-shop="config.public.storeId == 'professional-store'"
    :cart-count="count"
    :link="
      $t('header.link') != '' ? $t('header.link') : (formatLink('/') as string)
    "
    :without-icon="withoutIcon"
    :disable-wishlist="true"
    :hide-cart-icon="hideCartIcon"
    :is-account="isAccount"
    :link-text="$t('header.account.goToHome')"
    @open-cart="sidebarController.open"
    @open-wishlist="router.push(formatLink('/wishlist'))"
    @open-account="goToAccount"
    @go-to-link="
      $t('header.link') != '' ? $t('header.link') : router.push(formatLink('/'))
    "
    @link-to-home="goToHome"
  />

  <CheckoutSideCart :controller="sidebarController" />
</template>
